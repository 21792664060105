<template>
  <v-container fluid>
    <v-card class="email-communication-container content-card-padding">
      <div class="d-flex">
        <div class="first-col pa-4">
          <p class="title">
            Filters
          </p>
          <p class="subtitle-1 mt-4 mb-0">
            Roles
          </p>
          <v-checkbox
            v-model="allRoleSelected"
            :disabled="loading"
            label="All"
            color="primary"
            hide-details
            class="mt-1"
          />
          <v-checkbox
            v-for="role in roles"
            :key="role.value"
            v-model="role.selected"
            :disabled="loading"
            :label="role.label"
            color="primary"
            class="mt-1"
            hide-details
            @click.passive="filter"
          />
          <p class="subtitle-1 mt-8 mb-0">
            Units
          </p>
          <v-autocomplete
            v-model="selectedUnits"
            class="my-0 py-1"
            clearable
            multiple
            :disabled="loading"
            :items="units"
            item-text="name"
            item-value="id"
            no-data-text="No units available"
            placeholder="Select an unit..."
            return-object
            deletable-chips
            small-chips
            @change="filter"
          />
          <p class="subtitle-1 mt-4 mb-1">
            Language
          </p>
          <v-checkbox
            v-model="allLanguageSelected"
            :disabled="loading"
            label="All"
            color="primary"
            hide-details
            class="mt-1"
          />
          <v-checkbox
            v-for="lang in availableLanguages"
            :key="lang.label"
            v-model="lang.selected"
            :disabled="loading"
            :label="lang.label"
            color="primary"
            class="mt-1"
            hide-details
            @click.passive="filter"
          />
        </div>
        <v-divider vertical />
        <div class="second-col pa-4 flex-grow-0">
          <p class="subtitle-1">
            Recipients ({{ selectedRecipients }} / {{ recipients.length }})
          </p>
          <v-checkbox
            v-model="allRecipientSelected"
            :disabled="!recipients || !recipients.length"
            class="toggle-selection-all"
            label="Select all"
            color="primary"
            @change="selectAllRecipients"
          />
          <v-text-field
            v-model="search"
            label="Search"
            clearable
          />
          <!--
          Data table needs a header, even when its not displayed. When no header is provided,
          the search does not work.
        -->
          <v-data-table
            :items="recipients"
            :search="search"
            :headers="headers"
            :custom-filter="customFilter"
            :options.sync="pagination"
            :loading="isLoading.users"
            hide-default-header
            no-data-text=""
            hide-default-footer
            class="recipients-container"
          >
            <template #item="{ item }">
              <tr
                :active="item.selected"
                @click="item.selected = !item.selected"
              >
                <td class="py-1 px-3">
                  <v-checkbox
                    :input-value="item.selected"
                    primary
                    hide-details
                    class="ma-0"
                    color="primary"
                  />
                </td>
                <td class="py-1 pl-0">
                  <p class="ma-0">
                    {{ item.name }}
                  </p>
                  <p class="ma-0 caption">
                    {{ item.email }}
                  </p>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center pt-2 pagination-container">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              :total-visible="6"
            />
          </div>
        </div>
        <v-divider vertical />
        <div
          class="pa-4 third-column flex-grow-1"
        >
          <p class="subtitle-1">
            Send an email to {{ selectedRecipients }} users
          </p>
          <v-chip
            v-for="chip in chips"
            :key="chip"
            color="primary"
            text-color="white"
            class="font-weight-medium ml-2 my-1"
            label
          >
            {{ chip }}
          </v-chip>
          <v-text-field
            ref="subject"
            v-model="subject"
            label="Subject*"
            clearable
            placeholder="Enter a subject"
            class="mt-6"
            required
          />
          <label class="caption">Content*</label>
          <editor
            submit-button-text="Send"
            :submit-button-disabled="disabledSendButton"
            :editor-variable-slot="editorVariables"
            @submit="showConfirmationDialog = true"
            @keyUp="(text) => editorChange(text)"
          />
          <div class="mt-1">
            <label>* Mandatory field</label>
          </div>
          <emailPreview
            :show-send-preview="true"
            :send-button-disabled="disabledSendButton"
            :pre-fill-email="adminEmail"
            @send-preview="sendEmailPreview"
          />
        </div>
      </div>
    </v-card>
    <confirm-email-dialog
      v-model="showConfirmationDialog"
      :user-count="selectedRecipients"
      :affected-languages="selectedLanguages"
      @cancel="showConfirmationDialog = false"
      @send="sendEmail"
    />
  </v-container>
</template>

<script>
import {
  LanguageService, Editor, EmailPreview, EmailService, CompanyService
} from '@kickbox/common-admin';
import { mapGetters } from 'vuex';
import { KICKBOX_ROLES } from '@kickbox/common-util';
import { USERNAME, COMPANY_NAME, USER_UNIT } from '@kickbox/common-util/constants/editor-variables';
import userService from '@/services/userService';
import { ConfirmEmailDialog } from '../dialogs';

export default {
  name: 'UserEmailCommunication',
  components: {
    ConfirmEmailDialog,
    Editor,
    EmailPreview
  },
  data() {
    return {
      roles: [
        { label: 'Viewers', selected: false, value: KICKBOX_ROLES.VIEWER },
        { label: 'Kickboxers', selected: false, value: KICKBOX_ROLES.KICKBOXER },
        { label: 'Experts', selected: false, value: KICKBOX_ROLES.EXPERT },
        { label: 'Internal Service Providers', selected: false, value: KICKBOX_ROLES.SERVICE_PROVIDER },
      ],
      allRoleSelected: true,
      allLanguageSelected: true,
      availableLanguages: [],
      recipients: [],
      allRecipientSelected: true,
      pagination: {
        itemsPerPage: 25,
        page: 1
      },
      search: '',
      subject: '',
      emailText: '',
      chips: [],
      headers: [
        {
          value: 'name',
        },
        {
          value: 'email',
        },
      ],
      selectedLanguages: [],
      showConfirmationDialog: false,
      editorVariables: [USERNAME, COMPANY_NAME, USER_UNIT],
      units: [],
      selectedUnits: []
    };
  },
  computed: {
    ...mapGetters([
      'company',
      'isLoading',
      'serviceProviders',
      'users'
    ]),
    pages() {
      if (this.pagination.itemsPerPage == null || this.recipients.length === 0) {
        return 0;
      }
      return Math.ceil(this.recipients.length / this.pagination.itemsPerPage);
    },
    disabledSendButton() {
      return !this.subject || !this.emailText;
    },
    adminEmail() {
      return userService.getCurrentUserEmail();
    },
    selectedRecipients() {
      return this.recipients.filter((r) => r.selected).length;
    },
    loading() {
      return this.isLoading.users || this.isLoading.serviceProviders;
    },
    searchResult() {
      return this.recipients.filter((r) => this.customFilter(null, this.search, r));
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async () => {
      if (!from.name) {
        await userService.getUsersPerCompany();
      }
    });
  },
  watch: {
    users() {
      this.recipients = this.users;
      this.allRoleSelected = true;
      this.allLanguageSelected = true;
    },
    allRoleSelected() {
      if (this.allRoleSelected) {
        this.roles = this.roles.map((r) => ({
          ...r,
          selected: false
        }));
        this.filter();
      }
    },
    allLanguageSelected() {
      if (this.allLanguageSelected) {
        this.availableLanguages = this.availableLanguages.map((l) => ({
          ...l,
          selected: false
        }));
        this.filter();
      }
    }
  },
  async created() {
    const languages = await LanguageService.getLanguages(this.company.enabledLanguages);
    this.availableLanguages = languages.map((lang) => ({
      label: lang.key,
      selected: false,
    }));
    this.units = await CompanyService.getUnitsByCompany(this.company.parseObject) || [];
  },
  methods: {
    filter() {
      this.pagination.page = 1;
      this.filterRoles();
      this.filterLanguages();
      this.filterByUnit();
      this.updateChips();
    },
    filterRoles() {
      let selectedRoles = this.roles.filter((r) => r.selected);
      this.allRoleSelected = false;
      if (!selectedRoles.length) {
        this.allRoleSelected = true;
        selectedRoles = this.roles;
      }
      this.filterByRoles(selectedRoles);
    },
    filterByRoles(roles) {
      this.recipients = this.users.filter(
        (user) => roles.some((role) => user.role.includes(role.value))
      );
    },
    filterLanguages() {
      let selectedLanguages = this.availableLanguages.filter((r) => r.selected);
      this.allLanguageSelected = false;
      if (!selectedLanguages.length) {
        this.allLanguageSelected = true;
        selectedLanguages = this.availableLanguages;
      }
      this.filterByLanguage(selectedLanguages);
    },
    filterByLanguage(languages) {
      this.recipients = this.recipients.filter(
        (user) => languages.some((lang) => user.language === lang.label)
      );
    },
    filterByUnit() {
      if (this.selectedUnits.length) {
        this.recipients = this.recipients.filter(
          (user) => this.selectedUnits.some((unit) => user.unit && user.unit.id === unit.id)
        );
      }
    },
    customFilter(value, search, item) {
      const customSearch = search.toString().toLowerCase();
      return item.email.toLowerCase().includes(customSearch)
        || item.name.toLowerCase().includes(customSearch);
    },
    getEmailsByLanguage(users) {
      return users.reduce((acc, current) => {
        const userLanguage = current.language;
        if (!acc[userLanguage]) {
          acc[userLanguage] = [];
          acc[userLanguage].push(current.email);
        } else {
          acc[userLanguage].push(current.email);
        }
        return acc;
      }, {});
    },
    async sendEmail() {
      this.showConfirmationDialog = false;
      const users = this.recipients.filter((r) => r.selected);
      const usersToSend = this.getEmailsByLanguage(users);
      if (this.selectedRecipients === 0) {
        this.$store.dispatch('showSnackBar', { text: 'Select at least 1 recipient.' });
        return;
      }
      const emailProperties = this.getEmailProperties();
      Object.keys(usersToSend).forEach((lang) => {
        EmailService.sendEmailToUser(emailProperties, usersToSend[lang], lang);
      });
      this.$store.dispatch('showSnackBar', { text: 'Emails have been sent.' });
    },
    async sendEmailPreview(email) {
      const emailProperties = this.getEmailProperties();
      EmailService.sendPreviewEmail(emailProperties, email).then(() => {
        this.$store.dispatch('showSnackBar', { text: 'The email was sent.' });
      });
    },
    editorChange(text) {
      this.emailText = text;
    },
    getEmailProperties() {
      return {
        content: {
          subject: this.subject,
          text: this.emailText
        }
      };
    },
    updateChips() {
      const selectedRolesNames = this.roles.filter((r) => r.selected).map((r) => r.label);
      this.selectedLanguages = this.availableLanguages.filter((r) => r.selected)
        .map((l) => l.label);
      const selectedUnitsNames = this.selectedUnits.map((unit) => unit.name);
      this.chips = selectedRolesNames.concat(this.selectedLanguages, selectedUnitsNames);
    },
    selectAllRecipients(event) {
      this.recipients.forEach((r) => {
        // If I dont ignore this, and use a temporary variable,
        // ESLint complains variable is redundant
        // eslint-disable-next-line no-param-reassign
        r.selected = event;
      });
    }
  }
};
</script>

<style lang="scss">
  .email-communication-container {
    .pagination-container {
      margin: 0 -16px;
    }

    .first-col {
      width: 500px;
    }

    .second-col {
      width: 360px;

      .recipients-container {
        overflow-y: scroll;
        max-height: calc(100vh - 340px);
        min-height: calc(100vh - 340px);
        width: 330px;
      }
    }
  }
</style>
